/*eslint-disable*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/* Apollo */
import { GET_LOCAL_JOB_DETAILS } from '../../../schemas/queries'

/**
 * JobDetailPublishFormContainer
 *
 * @returns {Component}
 *
 */
class JobDetailPublishFormContainer extends Component {
  static propTypes = {
    jobDetail: PropTypes.object,
    client: PropTypes.object.isRequired,
    updateJobDetails: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    updateJobStatus: PropTypes.func.isRequired,
    isInvalid: PropTypes.bool.isRequired,
    refreshJob: PropTypes.func.isRequired,
    restoreJob: PropTypes.func.isRequired,
    updateError: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      publishedOn: null,
      editedOn: null,
      loading: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleRefreshJob = this.handleRefreshJob.bind(this)
  }

  /**
   *
   * @function handleSubmit - Main submission method for editing Job Detail and Job Status
   */

  handleRefreshJob() {
    const { client, refreshJob } = this.props

    const { jobDetail } = client.readQuery({
      query: GET_LOCAL_JOB_DETAILS
    })

    const { id } = jobDetail

    refreshJob({
      variables: {
        jobId: id
      }
    })
      .then(response => response.data.refreshJob)
      .then(status => {
        if (status.ok) {
          window.location.reload()
        }
      })
  }

  handleRestoreJob = () => {
    const { client, restoreJob } = this.props

    const { jobDetail } = client.readQuery({
      query: GET_LOCAL_JOB_DETAILS
    })

    const { id } = jobDetail

    restoreJob({
      variables: {
        jobId: id
      }
    })
      .then(response => response.data.restoreJob)
      .then(status => {
        if (status.ok) {
          window.location.reload()
        }
      })
  }

  handleSubmit() {
    const {
      client,
      updateJobDetails,
      updateJobStatus,
      updateError
    } = this.props

    this.setState({
      loading: true
    })

    const { jobDetail } = client.readQuery({
      query: GET_LOCAL_JOB_DETAILS
    })

    let screeningQuestions = jobDetail.screeningQuestions.map(obj => {
      return obj.question
    })
    screeningQuestions = screeningQuestions.filter(q => q)

    let resumeForwardingEmails = jobDetail.resumeForwardingEmails.map(obj => {
      return obj.email
    })
    resumeForwardingEmails = resumeForwardingEmails.filter(q => q)

    const jobBenefits = jobDetail.jobBenefits.map(
      jobBenefit => jobBenefit.benefitId
    )
    if (!jobDetail.jobTitleId) {
      console.log({ jobDetail })
      alert('You must select job title!')
      return
    }

    const payload = {
      degreeKey: jobDetail.degreeKey,
      jobLocationKey: jobDetail.jobLocationKey,
      // jobLocationId: jobDetail.jobLocationId,
      jobTypeKey: jobDetail.jobTypeKey,
      xpLvlKey: jobDetail.xpLvlKey,
      xpLvlId: jobDetail.xpLvlId,
      jobTitle: jobDetail.jobTitle,
      jobTitleRef: jobDetail.jobTitleRef,
      functionJobTitleId: jobDetail.jobTitleId,
      address: jobDetail.address,
      floorUnit: jobDetail.floorUnit,
      longitude: jobDetail.longitude,
      latitude: jobDetail.latitude,
      jobDescription: jobDetail.jobDescription,
      jobDescriptionHtml: jobDetail.jobDescriptionHtml,
      jobRequirements: jobDetail.jobRequirements,
      jobRequirementsHtml: jobDetail.jobRequirementsHtml,

      jobSkills: jobDetail.jobSkills,
      highlighted: jobDetail.highlighted,
      stickies:
        jobDetail.stickies &&
        (jobDetail.stickies.status === 'active' ||
          jobDetail.stickies.status === true)
          ? true
          : false,
      externalApplyUrl: jobDetail.externalApplyUrl,
      countryId: parseInt(jobDetail.countryId),
      screeningQuestions,
      resumeForwardingEmails,
      jobBenefitsId: jobBenefits,
      isRemoteWorldwide: jobDetail.isRemoteWorldwide,
      remoteCountryIds: jobDetail.remoteCountryIds,
      workArrangementId: jobDetail.workArrangementId,
      languageIds: jobDetail.languageIds,
      timezoneIds: jobDetail.timezoneIds,
      currencyId: jobDetail.currencyId,

      salaryTypeId: jobDetail.salaryTypeId
    }

    payload.isAllLocation = jobDetail.isAllLocation
    payload.jobLocationId = jobDetail.jobLocationId

    if (jobDetail.salaryTypeId == 1) {
      payload.salaryRangeFrom = parseInt(jobDetail.salaryRangeFrom)
      payload.salaryRangeTo = parseInt(jobDetail.salaryRangeTo)
    }

    if (jobDetail.salaryTypeId == 2) {
      payload.dailySalaryRangeFrom = parseInt(jobDetail.dailySalaryRangeFrom)
      payload.dailySalaryRangeTo = parseInt(jobDetail.dailySalaryRangeTo)
    }

    if (jobDetail.salaryTypeId == 3) {
      payload.hourlySalaryRangeFrom = parseInt(jobDetail.hourlySalaryRangeFrom)
      payload.hourlySalaryRangeTo = parseInt(jobDetail.hourlySalaryRangeTo)
    }

    console.log(payload, 'debugger for is all location')

    try {
      updateJobDetails({
        variables: {
          jobId: jobDetail.id,
          jobInput: payload
        }
      })
        .then(response => response.data.updateJob)
        .then(status => {
          if (status.ok) {
            updateJobStatus({
              variables: {
                jobId: jobDetail.id,
                statusKey: jobDetail.statusKey,
                changeRequiredReason:
                  jobDetail.statusKey === 'change_required'
                    ? jobDetail.changeRequiredReason
                    : null,
                rejectedReason:
                  jobDetail.statusKey === 'rejected'
                    ? jobDetail.rejectedReason
                    : null
              }
            })
              .then(response => response.data.updateJobStatus)
              .then(status => {
                if (status.ok) {
                  window.location.reload()
                } else {
                  updateError(true, status.message)
                  window.scrollTo(0, 0)
                  this.setState({
                    loading: false
                  })
                }
              })
          } else {
            updateError(true, status.message)
            this.setState({
              loading: false
            })
          }
        })
    } catch (err) {
      console.log('err', err)
    }
  }

  /**
   *
   * @function _renderStatusField
   * @returns {Component}
   */
  _renderStatusField() {
    const {
      jobDetail: { statusKey },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="statusKey">Status</Label>
        <Input
          type="select"
          name="statusKey"
          value={statusKey}
          onChange={handleChange}
          disabled={isInvalid || statusKey === 'company_pending_verification'}
        >
          <option value="active">Active</option>
          <option value="pending">Pending</option>
          <option value="change_required">Change Required</option>
          <option value="rejected">Rejected</option>
          <option value="draft">Draft</option>
          <option value="expired">Expired</option>
          <option value="closed">Closed</option>
          <option value="deleted">Deleted</option>
          <option value="company_pending_verification">
            Company Pending Verification
          </option>
        </Input>
        {statusKey === 'company_pending_verification' && (
          <div style={{ color: 'red' }}>
            This job will be active once its company document is verified
          </div>
        )}
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderStatusField
   * @returns {Component}
   */
  _renderRejectedReasonField() {
    const {
      jobDetail: { rejectedReason },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="rejectedReason">Rejected Reason</Label>
        <Input
          type="textarea"
          name="rejectedReason"
          id="rejectedReason"
          value={rejectedReason}
          onChange={handleChange}
          disabled={isInvalid}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderStatusField
   * @returns {Component}
   */
  _renderChangeRequiredReasonField() {
    const {
      jobDetail: { changeRequiredReason },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="changeRequiredReason">Changed Required Reason</Label>
        <Input
          type="textarea"
          name="changeRequiredReason"
          id="changeRequiredReason"
          value={changeRequiredReason}
          onChange={handleChange}
          disabled={isInvalid}
        />
      </FormGroup>
    )
  }

  /**
   * @param {DateTime} publishedOn
   * @param {DateTime} lastEdited
   * @param {DateTime} expiredAt
   * @param {Number} recruiterId
   * @param {String} recruiterFirstName
   * @param {String} recruiterLastName
   * @param {String} companyName
   * @param {Number} companyId
   * @param {String} statuskey
   * @returns {Component}
   */
  _renderJobMeta(
    publishedOn,
    lastEdited,
    expiredAt,
    recruiterId,
    recruiterFirstName,
    recruiterLastName,
    companyName,
    companyId,
    statusKey,
    refreshedAt,
    publishedAt,
    jdUrl,
    isCompanyVerified,
    deletedAt,
    lastEditRecruiterId,
    lastEditRecruiterFullname
  ) {
    return (
      <div>
        <p>Published on: {publishedAt ? formatDate(publishedAt) : '-'}</p>
        {deletedAt !== null ? (
          <Button size="sm" color="primary" onClick={this.handleRestoreJob}>
            Restore Job
          </Button>
        ) : null}
        <p />
        <p>Last Edited: {formatDate(lastEdited)}</p>
        <p>
          Expired On:{' '}
          {statusKey === 'pending' || statusKey === 'draft'
            ? '-'
            : formatDate(expiredAt)}
        </p>
        <p>
          Posted By:{' '}
          <a href={`/bossjob/user/${recruiterId}`}>
            {recruiterFirstName} {recruiterLastName}
          </a>
        </p>
        <p>
          Last Edited By:{' '}
          {lastEditRecruiterId && (
            <a href={`/bossjob/user/${lastEditRecruiterId}`}>
              {lastEditRecruiterFullname}
            </a>
          )}
        </p>
        <p>
          Company: <a href={`/bossjob/company/${companyId}`}>{companyName}</a>
        </p>
        <p>Company Verification Status: {isCompanyVerified.toString()}</p>
      </div>
    )
  }

  render() {
    const {
      jobDetail: {
        recruiterFirstName,
        recruiterLastName,
        createdAt,
        updatedAt,
        companyName,
        expiredAt,
        recruiterId,
        companyId,
        statusKey,
        refreshedAt,
        publishedAt,
        jdUrl,
        isCompanyVerified,
        deletedAt,
        lastEditRecruiterId,
        lastEditRecruiterFullname
      },
      isInvalid
    } = this.props

    return (
      <SectionWrapper sectionTitle="Publish">
        <Form>{this._renderStatusField()}</Form>
        {statusKey == 'change_required' ? (
          <Form>{this._renderChangeRequiredReasonField()}</Form>
        ) : null}
        {statusKey == 'rejected' ? (
          <Form>{this._renderRejectedReasonField()}</Form>
        ) : null}
        {this._renderJobMeta(
          createdAt,
          updatedAt,
          expiredAt,
          recruiterId,
          recruiterFirstName,
          recruiterLastName,
          companyName,
          companyId,
          statusKey,
          refreshedAt,
          publishedAt,
          jdUrl,
          isCompanyVerified,
          deletedAt,
          lastEditRecruiterId,
          lastEditRecruiterFullname
        )}
        <Button
          color="info"
          onClick={this.handleSubmit}
          disabled={isInvalid || this.state.loading}
        >
          Submit
        </Button>
      </SectionWrapper>
    )
  }
}

export default JobDetailPublishFormContainer
